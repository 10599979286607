/**
 * 公共变量
 */

import Dictionary, { DICTIONARY_NAME } from "@/common/dictionary"

/** 申请状态 */
export const  APPLY_STATUS_DICT = new Dictionary(DICTIONARY_NAME.ENT_APPLY_STATUS)
/** 租赁状态 */
export const  LEASE_STATUS_DICT = new Dictionary(DICTIONARY_NAME.ENT_LEASE_STATUS)
/** 资源状态 */
export const  PUBLISH_STATUS_DICT = new Dictionary(DICTIONARY_NAME.ENT_PUBLISH_STATUS)