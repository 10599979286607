




















import { defineComponent, onMounted, ref } from "@vue/composition-api";
import ElTablePlus, { TableActionClickCallbackContext, TableApis, TableColumn, TableRow, TableSearchOptionItem } from "@/components/el-table-plus.vue";
import { FormStructureItem } from "@/components/el-form-plus/index.vue";
import { $post } from "@/http/axios";
import ElFormPlus from "../../components/el-form-plus/index.vue";
import dayjs from "dayjs";
import {APPLY_STATUS_DICT,LEASE_STATUS_DICT, PUBLISH_STATUS_DICT} from "./common"
export default defineComponent({
    name: "SpaceResourceApply",
    components: { ElTablePlus, ElFormPlus },
    setup(props,ctx){
    
        // window.LEASE_STATUS_DICT = LEASE_STATUS_DICT
        onMounted(()=>{ })
        const tableRef = ref();
         const handleSubmitApply = async () =>{
             if(Object.entries(applyForm.value).map(item=>item[1]).some(item=>!item)){
                ctx.root.$message.error('请完整填写表单');
                 return;
             }
             console.log(`外部提交`,applyForm.value,applyContext.value)
            let {success} = await $post('/enterprisespaceresources/enterpriseSpaceResourcesApply',{
                ...applyForm.value,
                resourcesId:applyContext.value!.id 
            })
            if(success){
                ctx.root.$message.success('提交成功');
                showApplyFormDialog.value = false
                applyContext.value!.context.refresh()
            }

        }
        const apis:TableApis = {
            list:'/enterprisespaceresources/findApplyResourcesList',
            save:'/enterprisespaceresources/editEnterpriseSpaceResources',
            detail:'/enterprisespaceresources/getEnterpriseSpaceResourcesById',
            remove:'/enterprisespaceresources/deleteEnterpriseSpaceResourcesById',
        }
        const showApplyStatus =  ({row})=>{
            console.log(`查看申请状态`,row)
            if(row.status === '1'){
                ctx.root.$confirm(row.refuseReason,`驳回原因`,{
                    showCancelButton:false,
                    confirmButtonText:'关闭',
                })
            }

        }
        const columns:TableColumn[] = [
            { label: "发布方企业名称",prop: "enterpriseName",width:150},
            { label: "要求目标产值",prop: "targetValue"},
            { label: "要求年税收",prop: "annualTax"},
            { label: "厂房地址",prop: "address"},
            { label: "厂房面积(㎡)",prop: "idleArea"},
            { label: "承租方企业名称",prop: "enterpriseNameB"},
            { label: "承诺资产投入(万元)",prop: "assetInvestment"},
            { label: "承诺目标产值(万元)",prop: "targetValueB"},
            { label: "承诺年税收(万元)",prop: "annualTaxB"},
             { label: "需求面积(㎡)",prop: "demandArea"},
            {label:'申请时间',prop:'applyTime',formatter:val=>dayjs(val).format("YYYY-MM-DD HH:mm:ss")},
            {label:'申请状态',prop:'status',formatter:APPLY_STATUS_DICT,click:showApplyStatus},
            // { label: "租赁状态",prop: "leaseStatus",formatter:val=>LeaseStatusDict.get(val) },
            // { label: "联系方式",prop: "contactWay"},
            { label: "操作",type:'actions',fixed:'right',width:100,buttons:[
                {label: "资源详情",type:'primary',action:'DETAIL',primaryKey:"resourcesId"},
            ]},
        ]
        const formStructure:FormStructureItem[] = [
           
            { label:"发布方企业名称",key:'enterpriseName',type:'text'},
            { label:"要求目标产值",key:'targetValue',type:'text'},
            { label:"要求年税收",key:'annualTax',type:'text'},
            { label:"承租方企业名称",key:'enterpriseNameB',type:'text'},
            { label:"承诺资产投入(万元)",key:'assetInvestment',type:'text',span:8},
            { label:"承诺目标产值(万元)",key:'targetValueB',type:'text',span:8},
            { label:"承诺年税收(万元)",key:'annualTaxB',type:'text',span:8},
            { label:"厂房面积(㎡)",key:'idleArea',type:'text',span:8},
            { label:"需求面积(㎡)",key:'demandArea',type:'text',span:8},
            { label:"申请状态",key:'status',type:'select',options:APPLY_STATUS_DICT},
            { label:"申请时间",key:'createTime',type:'text'},
            { label:"厂房地址",key:'address',type:'textarea',span:24},
            { label:"生产工艺",key:'productionProcess',type:'textarea',span:24},
        ]
        const searchOptions:TableSearchOptionItem[]=[
            { label:'资源地址',type:'text',key:'landName'},
            { label:'联系人',type:'text',key:'entContact'},
            { label:'联系方式',type:'text',key:'contactWay'},
        ]
       
       /**
        * 申请表单相关逻辑
        */
       const applyFormStructure:FormStructureItem[]= [
           {label:'联系人',key:'entContact',type:'text',required:true},
           {label:'联系方式',key:'contactWay',type:'text',required:true},
           {label:'申请原因',key:'remarks',type:'textarea',span:24,required:true},
       ]
       let applyForm = ref()
       let applyContext = ref<{id:number,context:TableActionClickCallbackContext}>()
       const showApplyFormDialog = ref(false);

       
        return {
            columns,formStructure,tableRef,apis,searchOptions,
            showApplyFormDialog,applyFormStructure,applyContext,applyForm,handleSubmitApply
            }
    }
})
